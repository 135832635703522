<template>
  <div>
    <h4 class="title flex between">
      <span>接种方式：{{ preparationInfo.vaccSite }}</span>
      <div>
        <el-button size="small" plain class="return" @click="exportFile">导出</el-button>
        <el-button size="small" plain class="return" @click="$emit('changeShow',false)">返回列表页</el-button>
      </div>
    </h4>
    <table class="table">
      <tr>
        <th>方案编号</th>
        <td colspan="3">{{ activeInfo.number }}</td>
        <th>动物品系</th>
        <td>{{ activeInfo.strain }}</td>
      </tr>
      <tr>
        <td>雌鼠数量</td>
        <td>{{ preparationInfo.femaleNum }} 只</td>
        <td>雄鼠数量</td>
        <td>{{ preparationInfo.maleNum }} 只</td>
        <td>试剂种类</td>
        <td>{{ preparationInfo.cellType }}</td>
      </tr>
      <tr>
        <td>接种方式</td>
        <td colspan="3">{{ preparationInfo.vaccSite }}</td>
        <td>溶媒对照</td>
        <td>{{ preparationInfo.comparison }}</td>
      </tr>
      <tr>
        <td>接种目标浓度</td>
        <td colspan="5"><span class="text">{{ preparationInfo.cellCount }}</span></td>
      </tr>
      <tr>
        <td>配制剂量浓度</td>
        <td colspan="5"><span class="text">{{ preparationInfo.cellDensity }}</span></td>
      </tr>
      <tr>
        <td>接种体积</td>
        <td colspan="5"><span class="text">{{ preparationInfo.vaccVolume }}</span>μl</td>
      </tr>
      <tr>
        <td>配制条件</td>
        <td colspan="5">{{ preparationInfo.condition }}</td>
      </tr>
      <tr>
        <td>供试品有效期</td>
        <td colspan="5">{{ preparationInfo.expiryDate | formatDay }}</td>
      </tr>
      <tr>
        <td>剩余液处理方法</td>
        <td colspan="5">{{ preparationInfo.residualHandle }}</td>
      </tr>
      <tr>
        <td>细胞悬液配制人</td>
        <td colspan="2">{{ preparationInfo.createName }}</td>
        <td colspan="2">日期</td>
        <td>{{ preparationInfo.createTime  | formatDay }}</td>
      </tr>
      <template v-if="preparationInfo.step==1">
        <tr>
          <td>细胞悬液配制复核人</td>
          <td>{{ preparationInfo.reviewName }}</td>
          <td colspan="2">复核日期</td>
          <td>{{ preparationInfo.reviewDate | formatDay }}</td>
        </tr>
      </template>
    </table>
    <template v-if="checkState!=0">
      <h4 class="title">细胞悬液配制复核记录</h4>
      <template v-if="preparationInfo.step==0">
        <el-form ref="checkForm" size="small" :model="checkForm" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="checkForm.step" class="radioGroup">
              <el-radio :label="1">复核通过</el-radio>
              <el-radio :label="2">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" v-model="checkForm.reviewRemark" placeholder="请输入复核意见"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('checkForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <!--        复核完成-->
      <template v-else>
        <div class="desc">
          <p>复核结果：{{ preparationInfo.step == 1 ? '审核通过' : preparationInfo.step == 2 ? '审核拒绝' : '' }}</p>
          <p>复核意见：{{ preparationInfo.reviewRemark }}</p>
          <span>复核人：{{ preparationInfo.reviewName }}</span>
          <span>复核日期：{{ preparationInfo.reviewDate | formatDay }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
import FileSaver from "file-saver";

moment.locale("zh-cn")

export default {
  name: "modelingTable",
  props: ['id', 'preparationInfo', 'checkState'],
  data() {
    return {
      activeInfo: {},
      checkForm: {
        id: null,
        step: 1,
        reviewRemark: ''
      },
    }
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
  },
  computed: {
    validateType() {
      return this.checkForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  methods: {
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.checkForm.id = this.preparationInfo.id
          this.$putJson('/model/susDetail', this.checkForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changeShow', false)
            }
          })
        }
      })
    },
    exportFile() {
      // 导出
      let name = moment(new Date()).format('YYYY-MM-DD') + '细胞悬液配置';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.table {
  width: 820px;

  tr td:first-child {
    width: 180px;
  }
}
</style>
